import React from 'react';
import './Gallery.css'; // Import the CSS for the Gallery section

// Import images for the gallery
import image1 from '../assets/ourwork1.jpeg';
import image2 from '../assets/ourwork2.jpeg';
import image3 from '../assets/ourwork3.jpeg';
import image4 from '../assets/ourwork4.jpeg';
import image5 from '../assets/ourwork5.jpeg';
import image6 from '../assets/ourwork6.jpeg';
import image7 from '../assets/ourwork7.jpeg';
import image8 from '../assets/ourwork8.jpeg';

const Gallery = () => {
  return (
    <section className="section__container gallery__container">
      <h2 className="section__header">~ Our Work ~</h2>
      <div className="gallery__grid">
        <img src={image1} alt="gallery" />
        <img src={image2} alt="gallery" />
        <img src={image3} alt="gallery" />
        <img src={image4} alt="gallery" />
        <img src={image5} alt="gallery" />
        <img src={image6} alt="gallery" />
        <img src={image7} alt="gallery" />
        <img src={image8} alt="gallery" />
      </div>
      {/* <div className="gallery__btn">
        <button className="btn">VIEW GALLERY</button>
      </div> */}
    </section>
  );
};

export default Gallery;

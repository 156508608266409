import React from 'react';
import { HashRouter as Router } from 'react-router-dom'; // Use HashRouter for single-page navigation
import Header from './Components/Header'; // Importing the Header component
import HeroSection from './Components/HeroSection';
import About from './Components/About';
import Portfolio from './Components/Portfolio';
import Testimonial from './Components/Testimonial';
import Gallery from './Components/Gallery';
import Footer from './Components/Footer';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        {/* Header */}
        <Header />
        
        {/* Sections with matching IDs for navigation */}
        <section id="home">
          <HeroSection />
        </section>
        
        <section id="about">
          <About />
        </section>
        
        <section id="products">
          <Portfolio />
        </section>
        
        <section id="testimonial">
          <Testimonial />
        </section>

        <section id="ourwork">
          <Gallery/>
        </section>

        {/* Footer */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;

import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme, useMediaQuery } from '@mui/material';

function Header() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Detect if screen is medium or smaller

    // Function to toggle the drawer state
    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    // Drawer list for mobile view
    const drawerList = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {['Home', 'About', 'Products', 'Testimonial', 'Our Work'].map((text) => (
                    <ListItem button key={text} component="a" href={`#${text.toLowerCase().replace(" ", "")}`}>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <AppBar 
            position="fixed" 
            sx={{ 
                backgroundColor: '#f4f2e7', // Changed background color
                zIndex: theme.zIndex.drawer + 1, // Ensure the header stays on top
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center', // Center content vertically
            }}
        > 
            <Toolbar 
                sx={{ 
                    display: 'flex', 
                    justifyContent: isMobile ? 'space-between' : 'center', 
                    alignItems: 'center', 
                    width: '100%',
                }}
            >
                <Typography 
                    variant="h6" 
                    component="div" 
                    sx={{ flexGrow: isMobile ? 1 : 0, color: '#2C3E50', textAlign: isMobile ? 'left' : 'center' }}
                >
                    E.F.KOLAH'S
                </Typography>

                {/* If the screen is mobile, show the hamburger menu */}
                {isMobile ? (
                    <>
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                            sx={{ color: '#2C3E50' }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                            {drawerList()}
                        </Drawer>
                    </>
                ) : (
                    // Show buttons only on larger screens (non-mobile)
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
                        <Button href="#home" sx={{ color: '#2C3E50' }}>
                            Home
                        </Button>
                        <Button href="#about" sx={{ color: '#2C3E50' }}>
                            About
                        </Button>
                        <Button href="#products" sx={{ color: '#2C3E50' }}>
                            Products
                        </Button>
                        <Button href="#testimonial" sx={{ color: '#2C3E50' }}>
                            Testimonial
                        </Button>
                        <Button href="#ourwork" sx={{ color: '#2C3E50' }}>
                            Our Work
                        </Button>
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default Header;

import React from 'react';
import './portfolio.css'; // Import the CSS for the Portfolio section
import portfolio1 from '../assets/1.svg'; // Import the first portfolio image
import portfolio2 from '../assets/WhatsApp Image 2024-10-02 at 9.31.03 AM.jpeg'; // Import the second portfolio image
import portfolio3 from '../assets/3.jpeg'; // Import the third portfolio image
import portfolio4 from '../assets/2.svg'; // Import the fourth portfolio image

const Portfolio = () => {
  return (
    <section className="portfolio">
      <div className="portfolio__container">
        <h2 className="portfolio__header">Our Products</h2>
        <div className="portfolio__grid">
          <div className="portfolio__item">
            <img src={portfolio1} alt="Portfolio 1" />
            <div className="portfolio__overlay">
              <h3>Product 1</h3>
              <p>A short description of the project.</p>
            </div>
          </div>
          <div className="portfolio__item">
            <img src={portfolio2} alt="Portfolio 2" />
            <div className="portfolio__overlay">
              <h3>Product 2</h3>
              <p>A short description of the project.</p>
            </div>
          </div>
          <div className="portfolio__item">
            <img src={portfolio3} alt="Portfolio 3" />
            <div className="portfolio__overlay">
              <h3>Product 3</h3>
              <p>A short description of the project.</p>
            </div>
          </div>
          <div className="portfolio__item">
            <img src={portfolio4} alt="Portfolio 4" />
            <div className="portfolio__overlay">
              <h3>Product 4</h3>
              <p>A short description of the project.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;

import React from 'react';
import './About.css'; // Import the CSS for the About section
import aboutImage from '../assets/EfAbout.svg'; // Ensure this path is correct

const About = () => {
  return (
    <section className="about">
      <div className="about__content">
        <div className="about__image">
          <img src={aboutImage} alt="About E.F.KOLAH'S" />
        </div>
        <div className="about__text">
          <h2>About E.F.KOLAH'S</h2>
          <p>
            Our parent company, <strong>E.F. Kolah & Sons</strong>, was established during British rule by Shri. Eduljee Faramjee Kolah. He began crafting Natural Brewed Vinegar using sugarcane juice as the brewing medium, a practice rare in cities like Bombay and Calcutta, which typically offered only imported British brands like Heinz and Crosse & Blackwell.
          </p>
          <p>
            Over time, vinegar production became widespread in homes across India’s remote areas, particularly in states like U.P. and M.P. The fermentation and maturation process—called the New Orleans theory—takes five months to transform fresh raw juice into genuine natural vinegar.
          </p>
          <p>
            In 1950, Framroze J. Kolah, the next generation, expanded the business by adding traditional condiments, pickles, and chutneys to the product line. What began as a home-scale operation gradually evolved into a micro-scale business.
          </p>
          <p>
            Today, the company is led by Mr. Yazad F. Kolah, a seasoned professional with over 25 years of experience, alongside Mrs. Rasna Yazad Kolah, a microbiologist who oversees the manufacturing processes. Together, they have launched 11 varieties of Insta-Masalas, in addition to a range of pickles, chutneys, and brewed vinegar, positioning the company for growth.
          </p>
          <p>
            Now, with nearly 30 different varieties of pickles, chutneys, Insta-Masalas, and genuine vinegar, E.F.KOLAH'S is poised for expansion, seeking financing to turn the team's collective vision into reality.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;

import React, { useState } from 'react';
import './Testimonial.css'; // Import the CSS for the Testimonials section

// Import the images for the testimonials
import testimonialImage1 from '../assets/Prateek.jpg'; // Update with the correct path
import testimonialImage2 from '../assets/Surbhi.png'; // Update with the correct path
import testimonialImage3 from '../assets/Abhinav.png'; // Update with the correct path

const testimonials = [
    {
        image: testimonialImage1,
        text: "E.F. Kolah's vinegar has truly transformed my culinary experience! Its rich, tangy flavor elevates every dish, from salads to marinades. I appreciate that it's made from high-quality, natural ingredients, making it a healthy choice for my family. I can't imagine cooking without it now!",
        name: "Jessica Taylor"
    },
    {
        image: testimonialImage2,
        text: "I love using E.F. Kolah's vinegar in my recipes! It adds the perfect kick to dressings and sauces. The quality is outstanding, and I feel good knowing I’m using a product that is both delicious and made from natural ingredients. Highly recommend it to anyone who loves to cook!",
        name: "Michael Roberts"
    },
    {
        image: testimonialImage3,
        text: "E.F. Kolah's vinegar is a game changer in my kitchen. It has a vibrant flavor that enhances my dishes and keeps my family healthy. I've even started using it for pickling! This vinegar is now a staple in my pantry, and I can't get enough of it.",
        name: "Sarah Patel"
    }
];

const Testimonial = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const prevTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
    };

    return (
        <section className="section__container client__container" id="client">
            <h2 className="section__header">~ TESTIMONIALS ~</h2>
            <div className="client__card">
                <img src={testimonials[currentIndex].image} alt={`client ${currentIndex + 1}`} />
                <p>{testimonials[currentIndex].text}</p>
                <h4>{testimonials[currentIndex].name}</h4>
            </div>
            <button onClick={prevTestimonial}>Previous</button>
            <button onClick={nextTestimonial}>Next</button>
        </section>
    );
};

export default Testimonial;
